* {
  box-sizing: border-box;
  margin: 0;
  padding: auto;
}



.body {
  display: flex;
}

/* area do menu lateral de categorias */
.aside {
  background-color: cadetblue;
  height: 100%;
  width: 400px;
}


.aside h3 {
  text-align: center;
  margin: 10px;
}

.aside ul li {
  color: black;
  display: block;
  list-style-type: none;
  padding: 0.4px;
  position: relative;
  height: 2.94vh;
}

.aside ul li:hover {
  background-color: rgb(10, 111, 141);
}

.aside label {
  cursor: pointer;
}

.input-category {
  cursor: pointer;
  margin-right: 5px;
}

/* area da caixa de pesquisa da home */

.product-card {
  border: 1px solid cadetblue;
  display: inline-block;
  flex-wrap: wrap;
  font-size: 13px;
  margin-top: 5px;
  overflow-x: auto;
  width: 220px;
  height: 250px;
}

.Search-box {
  border: solid 2px cadetblue;
  border-radius: 10px;
  margin: 20px;
  text-align: center;
  width: 100%;
}


.linkCard{
  text-decoration: none;
  color: black;
}

.input-search {
  border-radius: 20px;
  margin-left: 150px;
  margin-top: 20px;
  padding: 5px;
  width: 60%;
}

.search-button {
  background: cadetblue;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  font-weight: 777;
  height: 32px;
  margin-left: 10px;
  width: 70px;
}

.cart-icon-link {
  color: cadetblue;
  margin-left: 20px;
  position: relative;
  top: 10px;
  text-decoration: none;
}

.number-cart-list {
  background-color: red;
  border-radius: 60%;
  color: black;
  font-size: 20px;
}

.text-home {
  margin-top: 20px;
}

.add-item-button {
  background: cadetblue;
  border: none;
  border-radius: 7px;
  color: white;
  cursor: pointer;
  font-weight: bold;
  height: 32px;
  padding: 6px 12px;
  transition: background-color 0.3s ease;
  width: 180px;
  margin-top: 10px;
}

.add-item-button:hover {
  background-color: rgb(10, 111, 141);
}

/* area da page carrinho vazio */
.empty-cart-div {
  margin-top: 50px;
  text-align: center;
}

/* area do carrinho com itens */
.cart {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.cart:nth-child( 1 ) {
  display: flex;
}

.cart-itens-div1 {
  margin: auto;
  width: 40%;
}

.cart-itens-div {
  border: 1px solid cadetblue;
  display: inline-flex;
  margin: auto;
  margin-left: 100px;
  width: 80%;
  text-decoration: none;
}

.free-shipping {
  color: rgb(109, 160, 95);
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  margin-top: 5px;
}


img {
  width: 100px;
}


.quantity {
  margin: 40px 5px;
  text-align: center;
}

.increase-button, .decrease-button, h1 {
  height: 20px;
  margin: auto;
}

/* area do checkout */
.review-products {
  background: rgb(230, 228, 228);
  border: 1px solid cadetblue;
  margin: auto;
  padding: 15px;
  width: 80%;
}

.clients-Data {
  background: rgb(230, 228, 228);
  border: 1px solid cadetblue;
  display: flex;
  flex-wrap: wrap;
  height: 200px;
  margin: auto;
  margin-top: 15px;
  padding: 15px;
  width: 80%;
}

.clients-Data input, select {
  background-color: white;
  border: none;
  border-radius: 7px;
  height: 30px;
  margin: 2px 3px;
}

.form-of-payment {
  background: rgb(230, 228, 228);
  border: 1px solid cadetblue;
  margin: auto;
  margin-top: 15px;
  padding: 15px;
  width: 80%;
}

.form-of-payment input {
  margin: auto 20px;
  text-align: center;
}

.buy-button, .checkout-button {
  background: cadetblue;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  font-weight: 777;
  height: 32px;
  margin-left: 130px;
  margin-top: 20px;
  width: 30%;
}

.buy-button:hover, .checkout-button:hover {
  background: rgb(10, 111, 141);
}

/* area do product details */
.product-details {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
  padding: 10px;
}

.title-thumb-price-productdetail {
  border: 2px solid cadetblue;
  margin-left: 30px;
  text-align: center;
  width: 500px;
}

.attributes {
  border: 2px solid cadetblue;
  margin-left: 30px;
  width: 500px;
}

.attributes h3 {
  text-align: center;
}

.cart-and-add-item {
  display: flex;
  margin: auto;
  margin-top: 20px;
  width: 50%;
}

.add-cart-button {
  background: cadetblue;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  font-weight: 777;
  height: 32px;
  margin-left: 20px;
  width: 30%;
}

.rating {
  border: 2px solid cadetblue;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  padding: 30px;
  width: 100%;
}

.form-rating {
  margin: auto;
  margin-top: 20px;
  width: 500px;
}

.textarea {
  margin-left: 28px;
  margin-top: 10px;
}

.button-rate {
  background: cadetblue;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  font-weight: 777;
  height: 32px;
  margin-left: 170px;
  margin-top: 20px;
  width: 30%;
}

.button-rate:hover {
  background: rgb(10, 111, 141);
}

@media (max-width: 768px) {
  .aside {
    width: 100%; /* Altera a largura para ocupar toda a largura disponível */
  }

  .aside ul li {
    height: auto; /* Altera a altura para ser definida automaticamente pelo conteúdo */
  }
}